<template>
    <div class="control">
        <div class="control-card">
            <img src="https://keyang2.tuzuu.com/uuo/ydj/4344a9aef440f28c36eca01631fc249f.png"/>
            <div class="card-text">激活 特药权益卡</div>
        </div>


        <div class="code-input-control">
            <div class="code-1"></div>
            <div class="code-2"></div>
            <div class="code-3"></div>
            <input
                type="text"
                maxlength="16"
                v-model="activeCode"
                @input="changeInput"
                @keydown="handEnter"
                @focus="hiddenBtn = true"
                @blur="hiddenBtn = false"
                inputmode="numeric"
                placeholder="请输入激活码"
            />
        </div>

        <div class="submit-btn" @click="submit" v-show="!hiddenBtn">下一步</div>

        <!--说明-->
        <van-popup
            v-model="showInform"
            position="bottom"
            class="info-form-popup"
            :close-on-click-overlay="false">
            <div class="info-form-content">
                <div class="info-form-title">健康告知</div>
                <div class="info-form-text">
                    <p>
                        为了保证权益持有人的保障权益在履约时不受影响,请确认权益持有人健康状况是否符合以下条件:
                    </p>

                    <p>1.权益持有人未曾被医疗机构确诊或诊断疑似患有恶性肿瘤。</p>

                    <p>2.权益持有人未曾出现肿瘤标志物或组织及其细胞病理学检查异常。</p>

                    <p>
                        权益持有人如果针对以上健康告知有任何未如实告知,本公司有权解除合同,对于合同解除前发生的保障事故,不承担赔付等法律责任。
                    </p>

                    <p>
                        备注:若您使用的是家庭版权益卡,您添加的权益持有人(您的子女配偶)也需满足本健康告知的条件。
                    </p>

                    <p>
                        <strong>等待期:</strong
                        >本服务权益自权益激活后90日为等待期，若您在上一权益期间届满后120日内申请重新续约的无等待期。等待期内无法申请使用相关服务。
                    </p>

                    <p>
                        <strong>适用年龄:</strong
                        >本服务权益适用申请年龄为出生28天-65周岁，非此年龄段的用户无法申请此服务相关权益。
                    </p>

                    <p>
                        在权益持有人使用本服务权益的过程中，如权益中的国内特药，海外特药，及其他相关服务内容进行升级迭代,在权益持有人同意的情况下,享有申请升级的权利。
                    </p>
                </div>
            </div>

            <div class="info-form-btn-box">
                <div class="flex">
                    <div class="label">请确认是否有以上病症</div>
                    <van-radio-group v-model="radioValue" direction="horizontal">
                        <van-radio :name="1" checked-color="#327F5C">有</van-radio>
                        <van-radio :name="0" checked-color="#327F5C">没有</van-radio>
                    </van-radio-group>
                </div>
                <div class="info-form-btn" @click="submitActive">
                    开始激活并绑定权益人
                </div>
            </div>
        </van-popup>

        <!--错误-->
        <van-popup
            v-model="showError"
            position="bottom"
            class="error-popup"
            round>
            <div class="error-title">温馨提示</div>
            <div class="error-content">
                <div class="error-content-info">
                    <p>您输入激活码错误, 请仔细核对</p>
                    <p>也可拨打客服电话确认.</p>
                </div>
                <div class="error-tel">400-0000-000</div>
                <div class="error-copy" @click="copy">复制</div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import {Dialog, Toast} from "vant";
import wxShare from "@/wechat/weChatTile";

export default {
    data() {
        return {
            showInform: true, //说明
            radioValue: -1, //1有，0无
            showError: false, //错误
            activeCode: "",
            hiddenBtn: false,  //true隐藏提交按钮
            initHeight: window.innerHeight
        }
    },
    async created() {
        const res = await axios.get('/ydj/check_is_activation')
        if (res.data.is_activation === 1) {
            await this.$router.push("/ydj/person-interests")
        }
    },
    mounted() {
        wxShare({
            title: '一站式健康管理服务平台',
            desc:'康来家园，中国健康管理领导者',
            link:window.location.origin + '/ydj',
        })
        window.onresize = () => {
            let height = document.documentElement.clientHeight || document.body.clientHeight;
            if (this.initHeight !== height) {
                this.hiddenBtn = true;
            } else {
                this.hiddenBtn = false;
            }
        }
    },
    beforeDestroy() {
        window.onresize = () => {
        };
    },
    methods: {
        copy() {
            const t = document.createElement("textarea");
            document.body.appendChild(t);
            t.value = "400-0000-000";
            t.select();
            document.execCommand("Copy");
            document.body.removeChild(t);
            Toast("复制成功");
        },
        //只能输入数字
        changeInput(event) {
            if (typeof event.data !== "number") {
                this.activeCode = this.activeCode.replace(/\D/g, "");
            }
            if(this.activeCode.length >=16){
                this.hiddenBtn = false
            }
        },
        //确认激活
        submitActive() {
            if (this.radioValue === -1) {
                Toast("请先勾选是否有以下病症");
            } else if (this.radioValue === 1) {
                Dialog({
                    title: "温馨提示",
                    message: "非常抱歉，您暂不符合激活条件。",
                });
            } else {
                this.showInform = false;
            }
        },
        async submit() {
            if (this.activeCode.length !== 16) {
                Toast("请输入16位的激活码");
                return;
            }
            Toast.loading({
                message: "提交中",
                duration: 0,
            });
            let res = (await axios.post("/ydj/check_power_card", {
                card_no: this.activeCode,
            }))
            Toast.clear();
            if (res.code !== 1) {
                this.showError = true;
            } else {
                await this.$router.replace({
                    path: "/ydj/active-form",
                    query: {
                        card_no: res.data.card_no,
                    }
                })
            }
        },
        //回车事件
        handEnter(event) {
            if (event.key === "Enter") {
                this.submit();
            }
        },

    }
}

</script>

<style scoped lang="less">
.control {
    height: 100vh;
    min-height: 600px;
    padding: 45px 28px 0;
    box-sizing: border-box;
    background-image: linear-gradient(to bottom,
    #f5f5f5,
    #f5f5f5 30%,
    white 30%,
    white 100%);

    .control-card {
        position: relative;
        filter: drop-shadow(0px 4px 6px rgba(54, 152, 103, 0.18));

        img {
            width: 100%;
            display: block;
        }

        .card-text {
            font-size: 25px;
            font-weight: 700;
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
            height: fit-content;
            text-align: center;
            margin: auto;
            color: #114512;
            line-height: 35px;
            letter-spacing: 1px;
        }
    }

    .code-title {
        text-align: center;
        margin-top: 28px;
        font-weight: 500;
        color: #232323;
        font-size: 16px;
    }

    .code-input-control {
        margin-top: 25px;
        position: relative;
        overflow: hidden;

        input {
            display: block;
            box-sizing: border-box;
            background: #f5f5f5;
            width: 100%;
            min-width: 0;
            margin: 0;
            padding: 7px;
            color: #232323;
            height: 50px;
            line-height: 25px;
            text-align: center;
            border: 0;
            resize: none;
            letter-spacing: 2px;
            user-select: auto;
            font-weight: bold;
            font-size: 23px;
        }

        .code-1 {
            width: 19px;
            position: absolute;
            height: 19px;
            border-radius: 50%;
            bottom: 9px;
            left: -5px;
            background: linear-gradient(118deg, #ffffff 0%, #b1eed1 100%);
        }

        .code-2 {
            width: 19px;
            height: 19px;
            position: absolute;
            border-radius: 50%;
            top: -7px;
            right: 10%;
            background: linear-gradient(90deg, #ffffff 0%, #e3e3e3 100%);
        }

        .code-3 {
            width: 28px;
            height: 28px;
            position: absolute;
            border-radius: 50%;
            bottom: -4px;
            right: 10px;
            background: linear-gradient(326deg, #ffffff 0%, #4ec288 100%);
        }
    }

    .submit-btn {
        bottom: 40px;
        width: 80%;
        position: fixed;
        text-align: center;
        background: #327f5c;
        border-radius: 23px;
        margin: 0px auto 0;
        left: 0;
        padding: 12px 0;
        font-weight: 600;
        color: #ffffff;
        font-size: 15px;
        right: 0;
    }
}

:deep(.info-form-popup) {
    height: 75vh;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(179deg,
    #fff0e2 0%,
    #daffed 48%,
    rgba(227, 254, 255, 0) 100%);
    background-size: 100% 180px;
    background-repeat: no-repeat;
    background-color: #f2f9ff;

    .info-form-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 0;
        padding: 10px 10px 0;

        .info-form-title {
            font-size: 25px;
            font-weight: bold;
            text-align: center;
            color: #144713;
            padding: 23px 0;
        }

        .info-form-text {
            flex: 1;
            height: 0;
            overflow: auto;
            //background: #FBFDFF;
            padding: 10px;
            text-indent: 2em;
            color: #232323;
            font-size: 16px;
            line-height: 25px;

            p {
                margin-bottom: 10px;
            }

            p:first-of-type {
                margin-bottom: 20px;
                font-weight: 700;
                line-height: 23px;
                background: #fbfdff;
                padding: 9px;
            }

            p:nth-of-type(5) {
                color: #666666;
                font-size: 15px;
            }
        }
    }

    .info-form-btn-box {
        padding: 19px 30px;
        box-shadow: 0 -3px 10px rgba(178, 178, 178, 0.26);

        .label {
            font-weight: 500;
            color: #327f5c;
            font-size: 15px;
            margin-right: 20px;
        }

        .info-form-btn {
            text-align: center;
            background: #327f5c;
            border-radius: 23px;
            padding: 12px 0;
            width: 80%;
            margin: 20px auto 0;
            font-weight: 500;
            color: #ffffff;
            font-size: 15px;
        }
    }
}

:deep(.error-popup) {
    .error-title {
        font-size: 18px;
        text-align: center;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #232323;
        letter-spacing: 1px;
        padding: 24px 0 25px;
        background-image: url("https://keyang2.tuzuu.com/uuo/ydj/cb1356750b1c223e1e8e9ccd3b470159.png");
        background-size: 100% auto;
    }

    .error-content {
        text-align: center;
        padding: 32px 0px 17px;

        .error-content-info {
            font-size: 15px;
            font-weight: 500;
            color: #666666;
            line-height: 30px;
        }

        .error-tel {
            border-radius: 30px;
            border: 1px solid #58b889;
            width: fit-content;
            margin: 20px auto 14px;
            font-size: 28px;
            font-weight: bold;
            color: #327f5c;
            letter-spacing: 1px;
            font-family: DINAlternate-Bold;
            padding: 10px 28px;
        }

        .error-copy {
            color: #3e2b37;
        }
    }
}
</style>